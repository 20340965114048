import React from "react";
import Layout from "./../Layout/Layout";
import Seperator from "./Seperator";
import NewsletterSubscribeTile from "./NewsletterSubscribeTile";


const Index = () => {
  return (
    <Layout>
      <NewsletterSubscribeTile />
      <Seperator />
    </Layout>
  );
};

export default Index;
