import React from "react";


const NewsletterSubscribeTile = () => {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl mx-auto px-1.5 md:px-10 pt-20 ">
        <div className=" ">
          <div className="bg-white md:w-2/4  w-11/12 m-auto px-4 md:px-12 py-20 pb-25">
            <p className="bg-white text-40 text-28 font-bold mb-14">
              Please subscribe to our monthly-ish newsletter and receive your download link immediately!
            </p>
            <div className="text-center text-40 text-28 font-bold mt-10 mb-10"><a className="underline hover:text-green-default" href="https://mailchi.mp/d9a08c162c6a/bioblinds-newsletter" target="__blank">Click Here!</a></div>
            <p className="md:text-2xl text-xl font-bold mb-10">
              Let’s jungle the cities together! 💚
            </p>
            <div className="text-xs">
            <p>You can change your mind at any time by clicking the unsubscribe link in the footer of any email you receive from us, or by contacting us at hello@thegreenskylineinitiative.com. <strong><br /><br />We will treat your information with respect.</strong> For more information about our privacy practices please have a look at ‘Get in touch’. By subscribing, you agree that we may process your information in accordance with these terms.

            </p><p><br />We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. Learn more about Mailchimp’s privacy practices <a href="https://mailchimp.com/legal/terms/" className="underline hover:text-green-default" target="__blank">here</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSubscribeTile;
